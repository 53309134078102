








import { ContainerMixin } from '@/common/mixin/containerMixin';
import { getLegacyAdminUrl } from '@/env';

export default ContainerMixin.extend({
  name: 'AdminFaqContainer',
  data() {
    return {
      menuName: '자주 묻는 질문',
    };
  },
  created: function () {
    const path = getLegacyAdminUrl() + '/help-faq/list';
    window.open(path);
  },
});
